@import "tenant-variables";

#insurance-update-history-card {
  font-family: "DM Sans" !important;
}


body {
  font-family: "DM Sans" !important;
  background-color: #f0eded !important;
  background-image: url("https://vervassets.blob.core.windows.net/web/nmj-bg.png");
  color: $text-color !important;

  .main-container {
    h4 {
      font-family: "DM Sans" !important;
      font-size: 16px !important;
      font-weight: 600;
    }

    h1 {

      // font-family: "Libre Baskerville", serif !important;
      // font-size: 27px !important;
      &.bold {
        font-weight: 600;
      }
    }
  }
}

p {
  color: $text-color !important;
}

.custom-radio {
  &.gender {
    .mat-error {
      display: none;
    }
  }
}

.verv-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.verv-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $secondary-color;
}

.mat-menu-item {
  color: $text-color !important;
}

.verv-theme {
  .table {
    color: $text-color !important;
  }

  .mat-drawer-container {
    color: $text-color !important;
  }

  #cash-transactions-container .drawer-container .btn-trancation-type .show_more {
    color: white;
  }
}

.verv-theme .mat-error {
  color: $text-color !important;
  font-weight: 600;
  font-size: 9px;
  margin-left: 0;
}

.page {
  // min-height: calc(100vh - 315px);
}

.mat-drawer-content {
  // left: -11px;
  background-color: $bg-color !important;
  // right: -11px;
}

.navbar-global-wrapper {
  background-color: #d1def1 !important;
  //   background-image: url("https://vervassets.blob.core.windows.net/web/CLOUD-HEADER.png");
  //   background-size: cover;
}

.scroll-to-top {
  .btn {
    .mat-icon {
      display: inline;
    }
  }

  // .btn-primary {
  //   background-color: $secondary-color !important;
  // }
}

.list-group-item.active {
  background-color: $green !important;
  border-color: darken($green, 10%) !important;
}

.badge-primary {
  background-color: $green !important;
  border-color: $green !important; //darken($green, 10%) !important;
  color: white !important;
}

.badge {
  &.badge-primary {
    background-color: $secondary-color !important;
    color: white !important;
  }
}

a {
  &.btn {
    display: flex;
    align-items: center;
  }
}

.btn-outline-primary {
  background-color: transparent !important;
  border-color: $secondary-color !important; //darken($green, 10%) !important;
  color: $secondary-color !important;
  font-weight: 500;
  border-radius: 0;
  text-decoration: none;
}

.btn-primary {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important; //darken($green, 10%) !important;
  color: white !important;
  font-weight: 500;
  border-radius: 0;

  &.active {
    border-color: #3db4b7 !important;
  }

  &:hover {
    background-color: $secondary-color !important;
    border-color: #6562f6 !important; //darken($green, 10%) !important;
    color: white !important;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show>&.dropdown-toggle:focus {
    box-shadow: none; // 0 0 0 0.2rem rgb(38 143 255 / 50%);
  }
}

.btn {
  direction: ltr;
  letter-spacing: 3px;
}

.btn-secondary {
  background-color: $secondary-color;
  border-color: darken($secondary-color, 10%) !important; //darken($green, 10%) !important;
  color: white !important;
  border-radius: 0;

  &:hover {
    background-color: white;
    color: $secondary-color !important;
    border-color: white !important;
  }
}

//   mat-sidenav {
//   width: 300px;
// }
.card.c-shadow {
  box-shadow: 3px 3px 9px #2e2e2e;
}

#member-account-dropdown-container {
  .btn {
    color: $text-color !important;

    span {
      margin-right: 0;
    }
  }

  .mat-icon {
    display: inline;
  }

  .selected-info {
    >div {
      width: auto;
    }

    &.account {
      display: none !important;
    }

    &.member {
      margin-right: 0px;
    }
  }
}

.btn {
  font-family: "DM Sans" !important;
  text-transform: uppercase;
  justify-content: start !important;
  font-size: 10px;
  min-height: 46px;
  padding: 12px;
  font-weight: 600;
  position: relative;
  // min-width: 150px;
  border-radius: 12px 0;

  &.menu-item {
    font-weight: 100;
  }

  span {
    margin-right: 64px;
  }

  .mat-icon {
    position: absolute;
    right: 16px;
    margin-right: 0 !important;
  }

  &.btn-danger {
    background-color: #d2def2;
    border: 1px solid darken($color: #d2def2, $amount: 10%);
    color: $secondary-color;
  }
}

#historical-graph-view-container {
  .btn-group {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .total-amount {
    h2 {
      font-family: "Libre Baskerville", serif !important;
      font-weight: 600;
      font-size: 42px;

      @media screen and (max-width: 992px) {
        margin-top: 16px !important;
      }
    }
  }

  .btn-group {
    .btn {
      min-width: auto;

      &.active {
        background-color: $secondary-color !important;
        color: white !important;
      }
    }

    @media screen and (max-width: 992px) {
      top: 15px !important;
    }
  }

  .links {
    direction: rtl;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    app-button {
      min-width: 200px;
      margin-right: 16px;
      margin-bottom: 8px;
    }
  }
}

#calculator-container {
  .page-content-actions {
    // direction: rtl;
    justify-content: end;
  }

  .calculator {

    p,
    li {
      color: $text-color;
    }
  }
}

.btn-light {
  color: $secondary-color !important;
  font-family: "DM Sans" !important;
  border-radius: 12px 0;
  border-color: #6562f6 !important;
  color: #6562f6 !important;
  background-color: white;

  &.custom {
    background-color: darken($color: $primary-color, $amount: 5%);
    border: 1px solid darken($color: $primary-color, $amount: 10%);
  }

  &.back {
    justify-content: end !important;

    span {
      margin-left: 64px;
      margin-right: 0;
    }

    .mat-icon {
      position: absolute;
      left: 16px;
      margin-left: 0 !important;
    }
  }
}

#app-consolidate-super-wrapper {
  .content {
    overflow-x: hidden;
  }
}

#consolidate-super-container {
  .alert {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 0 !important;

    h3 {
      font-family: "Libre Baskerville", serif !important;
      font-size: 16px !important;
    }
  }

  .card {
    &.requested {
      background-color: $gray !important;

      h6 {
        color: #2e2e2e !important;
      }

      label {
        color: darkgray !important;
      }

      .icon {
        color: $primary-color;

        .mat-icon {
          color: $primary-color !important;
        }
      }
    }

    &.pending {
      background-color: $gray !important;

      h6 {
        color: #2e2e2e !important;
      }

      label {
        color: darkgray !important;
      }

      .icon {
        color: $secondary-color;

        .mat-icon {
          color: $secondary-color !important;
        }
      }
    }
  }
}

#member-view-overview {
  .page-component {
    height: auto !important;

    .content {
      padding: unset !important;
    }

    .page-content-actions {
      padding: 20px;
      padding-top: 10px;
    }
  }

  #member-view-overview-container {
    .member-name {
      line-height: 40px !important;
      font-size: unset !important;

      @media only screen and (max-width: 992px) {
        font-size: 22px !important;
        line-height: 40px;
      }
    }

    .member-view-content {
      box-shadow: none;
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0px;
    }

    #app-readonly-container {
      border: none;
    }

    .header {
      background-color: #206865 !important;
      color: white; // lighten($color: $primary-color, $amount: 10%);
      font-size: 22px;
      font-family: "Libre Baskerville", serif !important;
      margin-bottom: unset !important;
      padding: 20px;
      padding-bottom: 10px;

      .hey-header {
        font-family: "DM Sans" !important;
        font-size: 12px !important;
        color: white !important;
        font-weight: bold !important;
        margin-bottom: 0px;
        line-height: 28px;
      }
    }

    img {
      display: none;
      height: 210px !important;
      margin-top: -179px !important;

      @media screen and (max-width: 992px) {
        height: 151px !important;
        margin-top: -121px !important;
        margin-right: -30px;
      }
    }
  }
}

#notification-dropdown-container {
  .btn-link {
    .mat-icon {
      color: $secondary-color !important;
    }
  }
}

#user-top-dropdown {
  #dropdownMenuButton {
    @media screen and (max-width: 992px) {
      width: 55px;
    }
  }

  .btn span {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .mat-icon {
    display: inline;
  }

  .dropdown-toggle {
    color: $text-color !important;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: $secondary-color;

    &::before {
      border-bottom: 10px solid $secondary-color;
    }

    a {
      color: white !important;
      text-transform: uppercase;
      font-size: 12px;

      &:hover {
        background-color: transparent !important;
        color: lightgray !important;
      }

      .mat-icon {
        font-size: 20px;
      }
    }

    li {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.page-header {
  .card {
    background-color: white;
    display: none;

    .mat-icon {
      color: $secondary-color;
    }
  }

  .page-title {
    font-family: "Libre Baskerville", serif !important;
    color: white;
    font-weight: bold;
    font-size: 32px;
    padding-bottom: 0;
  }

  .page-sub-title {
    color: $secondary-color;
  }
}

.mat-form-field {
  &.mat-form-field-invalid {
    .mat-form-field-flex {
      // border: 2px solid #f16d1f;
    }
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
  border: 1px solid #8996fb;
  border-radius: 4px !important;
}

.mat-form-field-underline {
  display: none;
}

a {
  color: $text-color !important;
  font-weight: 500;
  text-decoration: underline;
  font-size: 12px;

  &.mat-tooltip-trigger,
  &.dropdown-item,
  &.no-decoration {
    text-decoration: none;
  }
}

.description {
  color: $text-color;
  font-size: 12px;
}

ul {
  li {
    color: $text-color;
  }
}

.mat-expansion-panel-header-title {
  color: $secondary-color !important;
}

.list-group-item-action {
  color: $text-color !important;
}

.mat-checkbox-label {
  color: $text-color !important;
}

#app-readonly-container {
  .value {
    color: $text-color !important;
  }

  .title {
    color: $text-color !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }

  .app-copyable {
    font-size: 12px;
  }
}

h1 {
  font-family: "Libre Baskerville", serif !important;
  font-size: 27px !important;
}

h2,
h3,
h4,
h5 {
  color: $text-color !important;
  font-family: "Libre Baskerville", serif !important;

  &.subheader {
    font-family: "DM Sans" !important;
    font-weight: 700;
  }
}

a {
  &.btn {
    &.btn-link {
      display: grid;
    }
  }
}

//  .page-content {
//   app-button {
//     max-width: 50%;
//     margin: auto;
//   }
// }
.page-component {
  .page-content-actions {
    border-top: none !important;
    justify-content: end;
  }

  .content {
    border-radius: 12px 0 !important;
    overflow: hidden;
    border: none !important;
    box-shadow: -3px 3px rgba(232, 236, 241, 0.498);
  }

  a {
    color: $secondary-color !important;
    text-decoration: underline;

    &.btn-primary {
      color: white !important;
    }
  }

  .header {
    margin-top: 0px !important;
    margin-bottom: 20px !important;

    &.danger {
      .title {
        // text-decoration: underline;
      }
    }

    .mat-icon {
      display: none !important;
    }

    .card-subtitle {
      color: $secondary-color;
    }

    .title {
      color: #206865 !important;
      font-family: "Libre Baskerville", serif !important;
      font-size: 16px;
    }
  }
}

.historical-graph-component {
  .page-component {
    .total-amount {
      h2 {
        color: $secondary-color !important;
      }
    }

    .title,
    .mat-icon {
      //color: $secondary-color !important;
    }

    .content {
      //background: linear-gradient(lighten($green, 15%), $primary-color);
    }
  }
}

.mat-form-field.mat-focused .mat-form-field-label .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $secondary-color !important;
}

#rollin-container {
  .attach_money {
    .mat-form-field-prefix {
      display: block;
    }
  }

  #btnGoToDashboard {
    display: none;
  }
}

.mat-form-field {
  .mat-icon {
    color: $secondary-color !important;
  }

  .mat-form-field-prefix {
    display: none;
  }
}

.mat-form-field-label,
.mat-radio-group {
  color: #999999 !important;
}

#insurance-questionnaire-start-container {
  form {
    position: relative;
  }

  .option-2 {
    display: block;
  }

  .option-1 {
    display: none;
  }

  .top-progress {
    background-color: white !important;
    padding-top: 8px !important;

    .progress {
      .progress-bar {
        background-color: $green !important;
      }
    }
  }

  .components-actions {
    app-button {
      flex: none;
      max-width: initial;
    }
  }
}

#app-readonly-container {
  background: white !important;
  margin-bottom: 5px;
  /* border-left: 3px solid #d3d3d3; */
  padding: 5px 5px 0px 8px !important;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
}

.header {
  &.danger {
    color: #f47b63 !important;
  }
}

.components-actions {
  direction: rtl !important;
  border-top: none !important;
}

#switch-investments-container {
  .card {
    .header {
      background-color: transparent !important;
      border-bottom: none !important;
    }

    .components-actions {
      border-top: none !important;
    }

    &.option-0 {
      background-color: #f6cdb3 !important;
    }

    &.option-1 {
      background-color: #8598c9 !important;
    }

    &.option-2 {
      background-color: #3db4b7 !important;
    }
  }
}

#unit-prices-view-container {
  .apexcharts-inner {
    defs {
      linearGradient {
        stop:nth-child(1) {
          stop-color: #3db4b7;
        }

        stop:nth-child(2) {
          stop-color: lighten(#3db4b7, 15%);
        }

        stop:nth-child(3) {
          stop-color: lighten(#3db4b7, 50%);
        }
      }
    }
  }

  .apexcharts-series {
    path:nth-child(2) {
      stroke: #3db4b7 !important;
    }
  }
}

#account-balance-view-container {
  .apexcharts-series {
    path {
      fill: #50c9ce !important;
    }
  }

  .apexcharts-data-labels {
    text {
      fill: $primary-color !important;
    }
  }
}

.toast-container {
  .toast-success {
    background-color: #f5ffff !important;
    border: 1px solid #50c9ce;
    color: $secondary-color !important;
    background-image: url("https://vervassets.blob.core.windows.net/web/toaster-success.png") !important;
    font-weight: 500;
    padding: 32px;
  }

  .toast-error {
    background-color: #fba689 !important;
    color: $secondary-color !important;
    background-image: url("https://vervassets.blob.core.windows.net/web/toaster-error.png") !important;
    font-family: "DM Sans" !important;
    border: 1px solid #f16d1f;
    font-weight: 500;
    padding: 32px;
  }

  .toast-message {
    margin-left: 16px;
  }
}

.alert {
  &.alert-danger {
    border-color: #50c9ce !important;
    background-color: #f5ffff !important;
    color: $text-color !important;
    border-radius: 12px 0;
  }

  &.alert-warning {
    border-color: #50c9ce !important;
    background-color: #f5ffff !important;
    color: $text-color !important;
    border-radius: 12px 0;
  }

  &.alert-info {
    border-color: #50c9ce !important;
    background-color: #f5ffff !important;
    color: $text-color !important;
    border-radius: 12px 0;
  }

  &.alert-primary {
    border-color: #50c9ce !important;
    background-color: #f5ffff !important;
    color: $text-color !important;
    border-radius: 12px 0;
  }

  &.alert-success {
    border-color: darken(#e0f1f3, 10%) !important;
    background-color: #e0f1f3 !important;
    color: darken(#e0f1f3, 50%) !important;
  }
}

#personal-contributions-edit-container {
  .dd {
    border: 2px solid $primary-color !important;
  }

  .step {
    background-color: $gray;
  }

  .attach_money {
    .mat-form-field-prefix {
      display: block;
    }
  }

  // form {
  //   .alert {
  //     &.alert-warning {
  //       background-color: lighten(#f6cdb3, 5%) !important;
  //       border-color: darken(#f6cdb3, 5%) !important;
  //       color: $secondary-color !important;
  //     }
  //   }
  // }
}

#consolidate-your-syper-container {
  .transfers {
    .alert {
      &.alert-warning {
        .icon {
          background-color: #fff6ee !important;
          border-color: #f16d1f !important;
        }

        h6 {
          color: $primary-color !important;
        }
      }
    }
  }
}

app-scroll-up {
  .btn {
    min-width: 50px;

    .mat-icon {
      position: initial;
    }
  }
}

#contributions-limits-view-container {
  #gauge {
    .gauge--data {
      background-color: #3db4b7;
    }

    .gauge--background {
      background-color: #e0f1f3;
    }
  }

  .in,
  .out {
    color: #3db4b7;
  }

  .action {
    direction: rtl;
  }

  app-button {
    min-width: 200px;
  }
}

// #header-shape-gradient {
//   --color-stop: #f12c06;
//   --color-bot: #faed34;
// }
.apexcharts-area {
  stroke: darken($green, 10%);
}

#apexchartshistoricalchart {
  [id^="SvgjsLinearGradient"] {
    stop:first-child {
      stop-color: $green !important;
    }

    stop:nth-child(2) {
      stop-color: lighten($green, 30%) !important;
    }

    stop:nth-child(3) {
      stop-color: lighten($green, 50%) !important;
    }
  }
}

app-cash-transactions {
  .btn {
    &.btn-link {
      min-width: 50px;
    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #8996fb !important;
  border-radius: 8px;
  background: white;
}

#member-view-container {
  .app-slide-toggle {
    background-color: transparent;
    border-bottom: none;
    padding-left: 0;
  }

  .provider-item {
    &:hover {
      border-left-color: #50c9ce !important;

      .mat-icon {
        color: #50c9ce !important;
      }
    }
  }
}

app-entry-address-accurity {
  h5 {
    font-family: "DM Sans" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  mat-radio-group {
    // position: absolute;
  }
}

.mat-radio-label-content,
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field-label {
  mat-label {
    color: $text-color !important;
    font-weight: 600;
    font-size: 12px;
    padding-right: 4px;
  }

  &.mat-form-field-empty {
    mat-label {
      color: #999999 !important;
      font-weight: 100;
    }
  }
}

.mat-form-field {
  &.mat-form-field-invalid {
    .mat-form-field-outline-thick {
      color: #f16d1f !important;
    }
  }

  .mat-form-field-outline {
    background-color: white;
  }

  .mat-select.mat-select-invalid .mat-select-arrow {
    color: $secondary-color !important;
  }

  .mat-select-value {
    color: $text-color !important;
  }
}

.verv-theme {

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $primary-color;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-color;
  }
}

#maintenance-container {
  .left-side {
    width: 90% !important;
  }

  .right-side {
    min-width: 30% !important;

    .logo {
      background-image: url("https://vervassets.blob.core.windows.net/web/Logo.png");
      height: 70px;
      width: auto;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

#chat-view-container {
  bottom: 80px !important;

  button.mat-primary {}

  .timeline-badge {
    &.end {
      .mat-icon {
        color: $primary-color !important;
      }
    }
  }

  .timeline-title {
    font-size: 12px !important;

    .mat-icon {
      // color: darkseagreen;
    }
  }
}