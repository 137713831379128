@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

$primary-color: #1E1C4C;
$secondary-color: #6562F6;
$tertiary-color: #cfdbed;
$orange: #e79f4e;
$green: #3db4b7;
$gray: #efeeee;
$header-bgcolor: #8598c9;
$subheader-bgcolor: #F2F6F7;
$bg-color: #FFF6EE;
$text-color: $primary-color;

@font-face {
  font-family: "Druk Wide Medium";
   src: url("https://vervassets.blob.core.windows.net/web/fonts/DrukWide-Medium.woff2") format("woff2"), /* chrome firefox */
    url("https://vervassets.blob.core.windows.net/web/fonts/DrukWide-Medium.woff") format("woff"), /* chrome firefox */
    url("https://vervassets.blob.core.windows.net/web/fonts/DrukWide-Medium.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url('https://vervassets.blob.core.windows.net/web/fonts/DrukWide-Medium.otf') format("opentype");
}
